import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createVNode as _createVNode, withModifiers as _withModifiers, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
const _hoisted_1 = { class: "base-search" };
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
    key: 0,
    class: "base-search__item"
};
const _hoisted_4 = {
    key: 1,
    class: "base-search__item"
};
import { SEARCH_PROPS_MODE } from "@/components/@base/@types";
import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
import { BaseInput, BaseIcon, BaseLoader } from "@/components/@base";
import { clickOutside as vClickOutside } from "@/directives";
import { reactive, toRefs, watch, ref } from "vue";
import _ from "lodash";
const __default__ = { name: 'BaseSearch' };
export default /*@__PURE__*/ _defineComponent({
    ...__default__,
    props: {
        modelValue: { default: '' },
        placeholder: { default: 'Поиск' },
        mode: { default: SEARCH_PROPS_MODE.default },
        width: { default: '100%' },
        options: { default: null },
        loading: { type: Boolean, default: false }
    },
    emits: ["update:modelValue", "search", "refresh-offset", "reach-end-y"],
    setup(__props, { emit: __emit }) {
        _useCssVars(_ctx => ({
            "726b48be": (props.width)
        }));
        const props = __props;
        const emits = __emit;
        const { modelValue, loading } = toRefs(props);
        const selectedEl = ref(false);
        const selectScroll = ref(undefined);
        const data = reactive({
            isOpen: false,
            search: props.modelValue || '',
            debounceTime: 1000
        });
        const search = _.debounce(() => {
            if (selectedEl.value)
                return;
            if (props.mode === SEARCH_PROPS_MODE.catalog) {
                if (!data.search)
                    closeList();
                if (data.search)
                    emits('search', data.search);
            }
            emits('refresh-offset');
            emits('update:modelValue', data.search);
        }, data.debounceTime);
        const closeList = () => {
            data.isOpen = false;
        };
        const clickHandler = () => {
            selectedEl.value = false;
            if (props.mode === SEARCH_PROPS_MODE.catalog && props.modelValue) {
                if (data.isOpen) {
                    closeList();
                }
                else {
                    emits('search', data.search);
                }
            }
        };
        const select = (name) => {
            emits('update:modelValue', name);
            emits('refresh-offset');
            selectedEl.value = true;
            closeList();
        };
        const reachEndY = () => {
            if (selectScroll.value.ps.reach.y === 'end' && !props.loading) {
                emits('reach-end-y', data.search);
            }
        };
        watch(loading, next => {
            if (next)
                data.isOpen = true;
        });
        watch(modelValue, next => {
            data.search = next || '';
        }, { deep: true });
        return (_ctx, _cache) => {
            return (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_unref(BaseInput), _mergeProps({
                    modelValue: data.search,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((data.search) = $event)),
                    modelModifiers: { trim: true },
                    placeholder: props.placeholder
                }, _ctx.$attrs, {
                    onInput: _unref(search),
                    onClick: _withModifiers(clickHandler, ["stop"])
                }), {
                    actions: _withCtx(() => [
                        _createVNode(_unref(BaseIcon), {
                            class: "base-search__icon",
                            name: "search"
                        })
                    ]),
                    _: 1
                }, 16, ["modelValue", "placeholder", "onInput"]),
                (props.mode === _unref(SEARCH_PROPS_MODE).catalog && data.isOpen)
                    ? _withDirectives((_openBlock(), _createBlock(_unref(PerfectScrollbar), {
                        key: 0,
                        class: "base-search__list",
                        ref_key: "selectScroll",
                        ref: selectScroll,
                        onPsScrollDown: reachEndY
                    }, {
                        default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.options, (item) => {
                                return (_openBlock(), _createElementBlock("div", {
                                    class: "base-search__item",
                                    key: item.id,
                                    onClick: ($event) => (select(item.name))
                                }, _toDisplayString(item.name), 9, _hoisted_2));
                            }), 128)),
                            ((!props.options || props.options.length === 0) && !props.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_3, " По вашему запросу ничего не найдено "))
                                : _createCommentVNode("", true),
                            (props.loading)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                                    _createVNode(_unref(BaseLoader), { class: "base-search__loader" })
                                ]))
                                : _createCommentVNode("", true)
                        ]),
                        _: 1
                    })), [
                        [_unref(vClickOutside), closeList]
                    ])
                    : _createCommentVNode("", true)
            ]));
        };
    }
});
