import _axios from "@/plugins/axios"

export const makeSearch = async (catalogId = 1, text, ids = [], offset = 0) => {
  const { data } = await _axios.post('/catalog/search', {
    filters: {
    catalogId,
    text,
    ids
  },
  pagination: {
    limit: 20,
    offset
  }
  })

  return data
}

export const getCatalogs = async () => {
  const { data } = await _axios.get('/catalog/list')

  return data
} 