import { ref } from 'vue';
import { default as notify, TYPES } from "@/plugins/notify";
const EVENTS_TYPE = ['onmessage', 'onclose', 'onerror', 'onopen'];
const PING_INTERVAL_DELAY = 60000;
export function useWebSocket(url, { onOpen = () => { }, onMessage = () => { }, onError = () => { }, onClose = () => { }, autoReconnect = false, } = {}) {
    const data = ref(null);
    const isConnected = ref(false);
    const errorMessage = ref(null);
    const webSocket = ref(null);
    let pingInterval = null;
    const reconnectSettings = typeof autoReconnect === 'object' ? autoReconnect : {};
    const reconnectInterval = reconnectSettings.delay || 5000;
    const shouldReconnect = autoReconnect === true || typeof autoReconnect === 'object';
    const errorInform = (errMsg) => {
        notify({
            title: 'Ошибка!',
            text: errMsg,
            type: TYPES.error
        });
        console.error(errMsg);
    };
    const connect = () => {
        // if(shouldReconnect) {
        //   typeof reconnectSettings.onFailed !== 'undefined'
        //     ? reconnectSettings.onFailed()
        //     : errorInform('Что-то пошло не так')
        //   return;
        // }
        isConnected.value = false;
        webSocket.value = new WebSocket(url);
        onEvent();
    };
    const disconnect = () => {
        if (webSocket.value) {
            webSocket.value.close();
            webSocket.value = null;
        }
    };
    const open = (event) => {
        console.log('ws open');
        isConnected.value = true;
        console.log('WebSocket connected', event);
        const token = localStorage.getItem('crm-token');
        if (token && webSocket.value && webSocket.value.readyState === WebSocket.OPEN) {
            webSocket.value.send(token);
        }
        pingInterval = setInterval(() => {
            webSocket.value?.send('PING');
        }, PING_INTERVAL_DELAY);
        onOpen(event);
    };
    const onmesssage = (event) => {
        data.value = event.data;
        onMessage(event);
    };
    const onerror = (event) => {
        errorMessage.value = 'WebSocket error';
        console.log('WebSocket error', event);
        errorInform('Ошибка подключения');
        onError(event);
    };
    const close = (event) => {
        isConnected.value = false;
        clearInterval(pingInterval);
        // console.log("WebSocket disconnected", event);
        onClose(event);
        if (shouldReconnect) {
            setTimeout(connect, reconnectInterval);
        }
    };
    const onEvent = () => {
        if (webSocket.value) {
            EVENTS_TYPE.forEach((eventType) => {
                webSocket.value[eventType] = (event) => {
                    if (eventType === 'onopen') {
                        open(event);
                    }
                    if (eventType === 'onmessage') {
                        onmesssage(event);
                    }
                    if (eventType === 'onerror') {
                        onerror(event);
                    }
                    if (eventType === 'onclose') {
                        close(event);
                    }
                };
            });
        }
    };
    return { data, isConnected, errorMessage, webSocket, connect, disconnect };
}
