import { nextTick } from 'vue';
import { createVNode, render } from 'vue';
import ScriptElement from "@/components/scripts/ScriptElement.vue";
import { useScriptStore } from "@/store/script";
import useHistory from "@/hooks/useHistory";
import useUpdateHistoryValue from '@/hooks/useUpdateHistoryValue';
import { analyticsScriptStep } from '@/api/analytics';
import { debounce } from 'lodash';
import { makeSearch } from '@/api/catalogs';
import useOffset from '@/hooks/useOffset';
const inputTypeForFocus = [1, 2, 3, 4, 5, 6, 7];
const inputTypeForEntry = [1, 2, 3, 5, 6];
const selectTypeSearch = [32, 33];
const MultiSelectTypes = [10, 33];
const { offset, refreshOffset, increaseOffset } = useOffset();
export default {
    name: 'script-element',
    async mounted(el, binding) {
        const element = el.querySelector('element');
        if (!element)
            return;
        const id = element.id.toLowerCase();
        const currentElement = binding.value().elements.find((i) => String(i.id.toLowerCase()) === String(id));
        if (currentElement.value && MultiSelectTypes.includes(currentElement.type) && typeof currentElement.value === 'string') {
            currentElement.value = JSON.parse(currentElement.value);
        }
        if (currentElement.value && selectTypeSearch.includes(currentElement.type)) {
            const ids = currentElement.type === 33 ? currentElement.value : [currentElement.value];
            const data = await makeSearch(currentElement.props.catalogId, '', ids);
            currentElement.props.active = currentElement.type === 33 ? data.items : data.items[0];
        }
        const step = binding.value().step;
        if (step.stop)
            currentElement.props.tempValue = currentElement.value;
        const steps = binding.value().steps;
        const sections = binding.value().sections;
        const elements = binding.value().elements;
        const required = step.required;
        const vnode = createVNode(ScriptElement, { element: currentElement, required, stop: step.stop,
            onUpdate: (value) => {
                //console.log('current element', typeof currentElement.value)
                const oldValue = Array.isArray(currentElement.value) ? [...currentElement.value] : currentElement.value;
                if (step.stop) {
                    if (step.num === null)
                        return;
                    currentElement.props.tempValue = value;
                    steps.forEach((i) => {
                        if (i?.num > step?.num)
                            i.num = null;
                        i.last = false;
                        i.last = i?.num === step?.num;
                    });
                    sections?.forEach((i) => {
                        if (i?.priority > step?.sectionNum)
                            i.disabled = true;
                    });
                }
                if (!step.stop) {
                    currentElement.value = value;
                    if (!binding.value().demo) {
                        useUpdateHistoryValue(id, value);
                        useHistory();
                    }
                    binding.value().navigate(elements, steps, sections, !step.last ? step : null);
                    debouncedSetAnalyticsScriptStep(step);
                }
                let currentModal;
                if (useScriptStore().modals) {
                    const isValueArray = Array.isArray(value);
                    const checkValue = (val) => {
                        return Array.isArray(val) ? val.length : !!value;
                    };
                    const checkStrategy = (modal) => {
                        if (modal.strategy === 'and') {
                            if (isValueArray) {
                                return modal.value ? value.length === JSON.parse(modal.value).length && value.every((el) => JSON.parse(modal.value).includes(el)) : false;
                            }
                            else {
                                return modal.value ? JSON.parse(modal.value).includes(value) : false;
                            }
                        }
                        else if (modal.strategy === 'or') {
                            if (isValueArray) {
                                return modal.value ? value.length === JSON.parse(modal.value).length && value.some((el) => JSON.parse(modal.value).includes(el)) : false;
                            }
                            else {
                                return modal.value ? JSON.parse(modal.value).includes(value) : false;
                            }
                        }
                        else {
                            //console.log('last else')
                            if (isValueArray) {
                                if (value.length) {
                                    //console.log('last else if if')
                                    return modal.value ? value.length === JSON.parse(modal.value).length && value.every((el) => JSON.parse(modal.value).includes(el)) : false;
                                }
                                else {
                                    //console.log('last else if else')
                                    return checkValue(oldValue) ? modal.value === '' : false;
                                }
                            }
                            else {
                                if (value) {
                                    //console.log('last else else if')
                                    return modal.value ? JSON.parse(modal.value).includes(value) : false;
                                }
                                else {
                                    //console.log('last else else else')
                                    return checkValue(oldValue) ? modal.value === '' : false;
                                }
                            }
                        }
                    };
                    currentModal = useScriptStore().modals.filter((modal) => {
                        return modal.elementId === step.elementId && checkStrategy(modal);
                    })[0];
                }
                console.log('currentModal', currentModal);
                if (currentModal) {
                    binding.value().openModal(currentModal);
                }
            },
            onScrollTo: (id) => {
                binding.value().scrollTo(`step-${id}`, binding.value().scroll);
            },
            onConfirmEntry: () => {
                if (step.stop && inputTypeForEntry.includes(currentElement.type)) {
                    binding.value().jump(step);
                }
            },
            onSearch: async (str) => {
                if (!offset.value) {
                    currentElement.props.options = [];
                }
                currentElement.props.loading = true;
                const catalogs = await makeSearch(currentElement.props.catalogId, str, [], offset.value);
                if (offset.value) {
                    currentElement.props.options.push(...catalogs.items);
                }
                else {
                    currentElement.props.options = catalogs.items;
                }
                increaseOffset(20);
                currentElement.props.loading = false;
            },
            onOpened: () => {
                if (selectTypeSearch.includes(currentElement.type)) {
                    currentElement.props.options = [];
                }
            },
            onActive: (evt) => {
                currentElement.props.active = evt;
            },
            onRefreshSearch: () => {
                refreshOffset();
            } });
        const div = document.createElement('div');
        div.className = `script__element type-${currentElement.type}`;
        element.replaceWith(div);
        render(vnode, div);
        if (step.stop && inputTypeForFocus.includes(currentElement.type)) {
            nextTick(() => {
                const baseAttribute = `[elementid="${currentElement.id}"]`;
                const inputSelector = currentElement.type !== 4 ? `${baseAttribute} input` : `${baseAttribute} textarea`;
                const inputElement = el.querySelector(inputSelector);
                if (inputElement instanceof HTMLElement) {
                    inputElement.focus();
                }
            });
        }
    },
};
const debouncedSetAnalyticsScriptStep = debounce((step) => {
    const { script, clientId, analyticsSteps, modals } = useScriptStore();
    if (analyticsSteps.has(step.id))
        return;
    const SESSION_ID = localStorage.getItem('crm-analytics-session-id');
    const now = new Date();
    const createdAt = now.toISOString();
    const analyticStepData = {
        sessionId: SESSION_ID,
        scriptId: script?.id,
        scriptVersion: script?.version,
        stepId: step.id,
        clientId: clientId || 0,
        createdAt
    };
    analyticsScriptStep(analyticStepData);
    analyticsSteps.set(step.id, analyticStepData);
}, 500);
